import React from "react";
import styled, { keyframes, css } from "styled-components";

function TechStacks() {
  const row1 = [
    "https://v1.brittanychiang.com/img/skills/html.png",
    "https://v1.brittanychiang.com/img/skills/css.png",
    "https://v1.brittanychiang.com/img/skills/js.png",
    "https://v1.brittanychiang.com/img/skills/java.png",
    "https://imgs.search.brave.com/Z5KzvnbDLcIM0PWp7CcoTA7Rp3go5U8SMilVC0FGycQ/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy9k/L2Q1L1RhaWx3aW5k/X0NTU19Mb2dvLnN2/Zw",
    "https://v1.brittanychiang.com/img/skills/github.png",
    "https://imgs.search.brave.com/gJg0TR4nBnMb5gCcZsp_liZGYZV1kmIXT34gyo859Mw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8z/LzMwL1JlYWN0X0xv/Z29fU1ZHLnN2Zw",
    "https://imgs.search.brave.com/q8BgSN7bA36vzYcFJWFk9sbOBeZvglWXmoGi6_JDZDw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dC5icmFuZGZldGNo/LmlvL2lkcE90a3p3/VmIvaWRod2F4al9s/ZS5qcGVnP3VwZGF0/ZWQ9MTcxNjQwODQ0/OTc1MA",
  ];
  return (
   
      <AppContainer className="overflow-x-hidden">
        <Wrapper>
          <Note>
            Technology is best when it brings people together. — Matt Mullenweg
          </Note>
          <Marquee>
            <MarqueeGroup>
              {row1.map((el) => (
                <ImageGroup key={el}>
                  <Image src={el} />
                </ImageGroup>
              ))}
            </MarqueeGroup>
            <MarqueeGroup>
              {row1.map((el) => (
                <ImageGroup key={el}>
                  <Image src={el} />
                </ImageGroup>
              ))}
            </MarqueeGroup>
          </Marquee>
          <CardContainer>
            {row1.map((el) => (
              <ImageGroup key={el}>
                <Image src={el} />
              </ImageGroup>
            ))}
          </CardContainer>
        </Wrapper>
      </AppContainer>
   
  );
}

export default TechStacks;

const AppContainer = styled.div`
  width: auto;
  height: 25vh;
  color: #000000;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none; // Hides the container on mobile view
  }
`;


const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;



const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #222;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );

  @media (max-width: 768px) {
    display: none;
  }
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const CardContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    /* For two items per row */
    > * {
      flex: 0 0 48%; /* Two items per row, adjust if needed */
      margin-bottom: 16px; /* Optional: spacing between items */
    }
  }
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
  width: 900px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 10px 20px;
  box-shadow: 0px 0px 25px -5px;
  align-items: center;

  @media (max-width: 768px) {
    width: 40%;
    height: auto;
  }
`;

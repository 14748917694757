import React from "react";
import { motion } from "framer-motion";
import "../stylling/project.css";
import xopy from "../assets/project/xopy.png";
import travel from "../assets/project/travel-Agency.png";
import sorting from "../assets/project/image.png";

// Import tech stack logos
import htmllogo from "../assets/html.png";
import reactLogo from "../assets/reactJs.png";
import tailwindLogo from "../assets/tailwind-css.png";
import expressLogo from "../assets/expressJs.png";
import nodeLogo from "../assets/node.png";
import mongodbLogo from "../assets/mongodb.png";

const Projects = () => {
  const projects = [
    {
      title: "Xopy",
      image: xopy,
      url: "https://www.xopy.tech/",
      description:
        "A Cloud based Web-portal for Convenient and Secure File Sharing to Local Photocopy Centers.",
      techStack: [
        { name: "React", logo: reactLogo },
        { name: "Tailwind CSS", logo: tailwindLogo },
        { name: "Express.js", logo: expressLogo },
        { name: "Node.js", logo: nodeLogo },
        { name: "MongoDB", logo: mongodbLogo },
      ],
    },
    {
      title: "Travel Agency",
      image: travel,
      url: "https://travelagency.animishchopade.in/",
      description:
        "A Responsive Travel Agency Website to book all your dream destination tours.",
      techStack: [
        { name: "HTML", logo: htmllogo },
        { name: "React", logo: reactLogo },
        { name: "Tailwind CSS", logo: tailwindLogo },
      ],
    },
    {
      title: "Wick Wear",
      image: sorting,
      url: "https://WeakWear.animishchopade.in/",
      description:
        "Inspired by John Wick's world, a sleek e-commerce platform for efficient product management.",
      techStack: [
        { name: "React", logo: reactLogo },
        { name: "Tailwind CSS", logo: tailwindLogo },
        { name: "Node.js", logo: nodeLogo },
        { name: "Express.js", logo: expressLogo },
        { name: "MongoDB", logo: mongodbLogo },
      ],
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section id="projects">
      <div className="project px-4">
        <motion.div
          className="mb-12 text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          variants={titleVariants}
        >
          <h1 className="flex justify-center md:text-[3rem] text-4xl p-2">
            Projects
          </h1>
          <h1 className="">(more coming soon)</h1>
        </motion.div>

        <motion.div
          className="flex flex-col md:flex-row gap-8 flex-wrap justify-center items-center"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
        >
          {projects.map((project, index) => (
            <motion.div
              className="cards text-center md:px-0 px-4"
              key={index}
              variants={itemVariants}
              whileHover={{ scale: 1.05, y: -5 }}
              whileTap={{ scale: 0.95 }}
            >
              <p className="heading">{project.title}</p>
              <div className="image-container">
                <img
                  src={project.image}
                  className="w-full h-auto px-4"
                  alt={project.title}
                />
                <motion.a
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="visit-button"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Visit Website
                </motion.a>
              </div>
              <p className="text-pretty mt-2">{project.description}</p>
              <div className="tech-stack mt-2">
                <p className="font-bold mb-2">Tech Stack:</p>
                <div className="flex flex-wrap justify-center gap-2">
                  {project.techStack.map((tech, techIndex) => (
                    <div key={techIndex} className="flex items-center">
                      <img
                        src={tech.logo}
                        alt={tech.name}
                        className="w-8 object-cover mr-1"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;

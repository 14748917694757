import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import svg2 from "../assets/hero-shape-2.svg";
import svg1 from "../assets/hero-shape-1.svg";

const HeroSection = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const svgContainers = container.querySelectorAll(".svg-container");
    const paths = container.querySelectorAll(".svg-container svg path");

    const getPathString = (x, y, width) =>
      `M 10 50 Q ${x} ${y} ${width - 10} 50`;

    const setupAnimation = (svgContainer, path, index) => {
      const svgElement = svgContainer.querySelector("svg");
      const rect = svgContainer.getBoundingClientRect();
      const width = rect.width;

      // Set initial path
      gsap.set(path, { attr: { d: getPathString(width / 2, 50, width) } });

      const onMouseMove = (event) => {
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        gsap.to(path, {
          attr: { d: getPathString(x, y, width) },
          duration: 0.3,
          ease: "power2.out",
        });
      };

      const onMouseLeave = () => {
        gsap.to(path, {
          attr: { d: getPathString(width / 2, 50, width) },
          duration: 1,
          ease: "elastic.out(1, 0.3)",
        });
      };

      svgContainer.addEventListener("mousemove", onMouseMove);
      svgContainer.addEventListener("mouseleave", onMouseLeave);

      // Responsive adjustment
      const resizeObserver = new ResizeObserver(() => {
        const newRect = svgContainer.getBoundingClientRect();
        const newWidth = newRect.width;
        svgElement.setAttribute("width", newWidth);
        gsap.set(path, {
          attr: { d: getPathString(newWidth / 2, 50, newWidth) },
        });
      });

      resizeObserver.observe(svgContainer);

      return () => {
        svgContainer.removeEventListener("mousemove", onMouseMove);
        svgContainer.removeEventListener("mouseleave", onMouseLeave);
        resizeObserver.disconnect();
      };
    };

    const cleanupFunctions = Array.from(svgContainers).map((container, index) =>
      setupAnimation(container, paths[index], index)
    );

    return () => cleanupFunctions.forEach((cleanup) => cleanup());
  }, []);

  return (
    <section id="hero-section" ref={containerRef} className="w-full relative">
      <div
        className="absolute left-0 top-0 -z-10 h-full w-full opacity-20"
        style={{
          backgroundImage:
            "linear-gradient(rgb(62, 125, 255) 0%, rgba(62, 125, 255, 0) 100%)",
        }}
      ></div>

      <img
        src={svg1}
        className="absolute left-0 top-0 -z-10"
        alt="svg1"
        style={{ color: "transparent" }}
      />
      <img
        src={svg2}
        className="absolute right-0 top-0 -z-10"
        alt="svg2"
        style={{ color: "transparent" }}
      />
      <div className="hero-section text-center my-auto p-4">
        <div className="svg-container w-full h-[100px] mb-4">
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M 10 50 Q 500 50 990 50"
              stroke="black"
              fill="transparent"
              strokeWidth="1.2"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl md:text-6xl lg:text-7xl  mb-3">
            Hi, I'm Animish
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl">
            Web Developer | Biker | Movie Enthusiast
          </p>
        </div>
        <div className="svg-container w-full h-[100px] mt-4">
          <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M 10 50 Q 500 50 990 50"
              stroke="black"
              fill="transparent"
              strokeWidth="1.2"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import React from "react";
import { motion } from "framer-motion";
import photo from "../assets/profilePhoto.png";
import { CiKeyboard } from "react-icons/ci";
import { BiCameraMovie } from "react-icons/bi";
import { GiFullMotorcycleHelmet } from "react-icons/gi";

const About = () => {
  return (
    <section
      id="about"
      className="py-20 bg-gradient-to-b md:h-[80vh] h-auto"
    >
      <div className="bg-svg mx-auto px-4 sm:px-6 lg:px-8 md:mt-14">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 1 }}
            className="lg:w-1/3 mb-10 lg:mb-0 "
          >
            <img
              src={photo}
              alt="Animish"
              className="rounded-full w-64 h-64 object-cover shadow-2xl border-4 border-white scale-x-[-1] mx-auto"
            />
          </motion.div>
          <div className="lg:w-2/3 lg:pl-12">
            <motion.h2
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1 }}
              className="md:text-[3rem] text-[2.5rem] font-semibold mb-6 text-gray-800"
            >
              A Little About Me
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="md:text-lg text-sm text-gray-600 mb-6"
            >
              I'm a web developer focused on front-end technologies. I have a
              BTech in Electronics and Telecommunication Engineering from
              Viit, Pune.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1, delay: 0.4 }}
              className="md:text-lg text-sm text-gray-600 mb-6"
            >
              I strive to create beautiful, responsive, and user-friendly
              websites that leave a lasting impression. When I'm not coding, you
              can find me exploring new roads on my bike or immersing myself in
              the world of cinema.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1, delay: 0.6 }}
              className="flex flex-wrap justify-start items-center gap-6 mt-10"
            >
              <SkillItem icon={CiKeyboard} text="Web Developer" />
              <SkillItem icon={GiFullMotorcycleHelmet} text="Motohead" />
              <SkillItem icon={BiCameraMovie} text="Cinema Enthusiast" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SkillItem = ({ icon: Icon, text }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    whileInView={{ opacity: 1, scale: 1 }}
    viewport={{ once: true, amount: 0.8 }}
    transition={{ duration: 0.5 }}
    className="skill-item flex items-center bg-white p-3 rounded-full shadow-md"
  >
    <Icon className="text-2xl text-blue-500 mr-2" />
    <span className="text-gray-800 font-medium">{text}</span>
  </motion.div>
);

export default About;
import React from "react";
import resume from "../Resume/Animish_Chopade_Resume.pdf";

const Resume = () => {
  const openResume = () => {
    window.open(resume, "_blank");
  };

  return (
    <div className="resume h-[39vh] ">
      <div className="overlay">
        <div className="flex flex-col items-center gap-6">
          <h1 className="text-3xl uppercase mt-20">Check out my résumé!</h1>
          <button
            onClick={openResume}
            className="text-xl border-2 hover:scale-110 transition-all ease-in-out duration-300 px-6 py-2 bg-none border-white"
          >
            Grab a Copy
          </button>
        </div>
      </div>
    </div>
  );
};

export default Resume;

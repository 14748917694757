import React, { useState, useRef } from "react";
import { RiMenu3Fill, RiCloseLine } from "react-icons/ri";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import logo from "../assets/logo.png";
import "../stylling/Header.css";

gsap.registerPlugin(ScrollToPlugin);

const MobileMenu = ({ isOpen, onClose }) => {
  const menuRef = useRef(null);

  const handleScrollTo = (id) => {
    gsap.to(window, { duration: 2.0, scrollTo: { y: `#${id}`, offsetY: 50 } });
    onClose();
  };

  return (
    <div
      ref={menuRef}
      className={`fixed top-0 left-0 w-full h-full bg-white shadow-md text-center z-50 transform transition-transform duration-500  flex flex-col items-center justify-center ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <button
        className="absolute top-8 right-12 text-2xl rounded-full"
        onClick={onClose}
      >
        <RiCloseLine className="text-4xl cursor-pointer" />
      </button>

      <div onClick={() => handleScrollTo("about")} className="hover:underline">
        <span className="py-2 hover:underline text-[3rem] mt-12">About</span>
      </div>

      <div onClick={() => handleScrollTo("experience")}>
        <span className="py-2 hover:underline text-[3rem]">Experience</span>
      </div>
      <div onClick={() => handleScrollTo("projects")}>
        <span className="py-2 hover:underline text-[3rem]">Work</span>
      </div>
      <div onClick={() => handleScrollTo("footer")} className="hover:underline">
        <span className="py-2 hover:underline text-[3rem]">Contact</span>
      </div>
    </div>
  );
};

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from(".header ", {
      duration: 1,
      opacity: 0,
      y: 100,
      filter: "blur(10px)",
    });
    tl.from("logo", {
      duration: 1,
      opacity: 0,
      y: 40,
      stagger: 0.2,
      filter: "blur(10px)",
    });
  });

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScrollTo = (id) => {
    gsap.to(window, { duration: 1.5, scrollTo: { y: `#${id}`, offsetY: 50 } });
  };

  return (
    <>
      <div className="w-full h-auto px-6 header pt-6 text-xl font-medium cursor-pointer top-0 left-0 right-0 relative">
        
        <div className="flex justify-between items-center">
          
          <span className="logo">
            <img src={logo} alt="logo" className="w-[150px]" />
          </span>
          <div className="hidden  sm:flex gap-8 lg:gap-12 ">
            <span
              onClick={() => handleScrollTo("about")}
              className="md:text-[1rem] py-0 navlink logo"
            >
              About
            </span>

            <span
              onClick={() => handleScrollTo("experience")}
              className="md:text-[1rem] navlink logo"
            >
              Experience
            </span>
            <span
              onClick={() => handleScrollTo("projects")}
              className="md:text-[1rem] navlink logo"
            >
              Work
            </span>
            <span
              onClick={() => handleScrollTo("footer")}
              className="md:text-[1rem] navlink logo"
            >
              Contact
            </span>
          </div>
          
          <button className="sm:hidden " onClick={toggleMenu}>
            <div className="md:hidden flex items-center">
              <RiMenu3Fill className="text-2xl cursor-pointer" />
            </div>
          </button>
          
        </div>
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} onClose={toggleMenu} />
    </>
  );
};

export default Header;

import React, { useState } from "react";

import Loader from "./Loader";
import Header from "./Header";
import About from "./About";
import HeroSection from "./HeroSection";
import Experience from "./Experience";
import Projects from "./Projects";
import Scrollontop from "./Scrollontop";
import Resume from "./Resume";
import Footer2 from "./Footer2";
import TechStacks from "./TechStacks";

const Home = () => {
  const [showLoader, setShowLoader] = useState(true);

  const handleAnimationComplete = () => {
    setShowLoader(false);
  };

  return (
    <div className="smooth-wrapper relative">
      {showLoader ? (
        <Loader onAnimationComplete={handleAnimationComplete} />
      ) : (
        <div className="smooth-content">
          <Scrollontop />
          <Header />

          <div className=" md:mt-8">
            <HeroSection />
          </div>
          <div className="md:p-8 px-2 h-auto">
            <About />
          </div>
          <div className="m-auto">
            <Resume />
          </div>

          <Experience />
          <TechStacks />
          <Projects />
          <Footer2 />
        </div>
      )}
    </div>
  );
};

export default Home;

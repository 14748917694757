import React from "react";
import RevealLink from "./Footer.tsx";

const Footer2 = () => {
  return (
    <section id="footer">
      <div className="flex flex-col bg-footerImage md:h-auto h-[50vh]">
        <RevealLink />
      </div>
    </section>
  );
};

export default Footer2;

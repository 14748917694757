import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const SkillBox = ({ skill, isAnimating }) => (
  <motion.div
    className="border border-gray-300 rounded-md px-4 py-2 text-lg cursor-pointer"
    whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(0,0,0,0.2)" }}
    animate={
      isAnimating
        ? {
            rotateY: [0, 180, 360],
            scale: [1, 1.2, 1],
            backgroundColor: ["#ffffff", "#f0f0f0", "#ffffff"],
          }
        : {}
    }
    transition={{
      duration: 1.5,
      ease: "easeInOut",
      times: [0, 0.5, 1],
      repeat: 0,
    }}
  >
    {skill}
  </motion.div>
);

const SkillCategory = ({ title, skills }) => {
  const [animatingIndex, setAnimatingIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatingIndex((prevIndex) => (prevIndex + 1) % skills.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [skills.length]);

  return (
    <motion.div
      className="bg-white p-4 rounded-lg shadow-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="flex flex-wrap gap-2">
        {skills.map((skill, index) => (
          <SkillBox
            key={index}
            skill={skill}
            isAnimating={index === animatingIndex}
          />
        ))}
      </div>
    </motion.div>
  );
};

const Skills = () => {
  const skillsData = {
    Frontend: ["HTML", "CSS", "Tailwind", "Flexbox", "React.js", "Javascript"],
    Backend: ["Node.js", "Express.js", "REST API"],
    Database: ["Mysql", "MongoDB", "Firebase"],
    Others: ["Java", "Git", "Github", "OOPS", "GSAP", "Framer Motion"],
  };

  return (
    <section id="experience">
      <div className="max-w-5xl mx-auto p-2 md:h-[74vh] h-auto mt-16">
        <motion.h2
          className="font-bold text-center mb-6 md:text-[3rem] text-4xl experience-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Skills
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-12/12">
          {Object.entries(skillsData).map(([category, skills], index) => (
            <motion.div
              key={category}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <SkillCategory title={category} skills={skills} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;

import React from "react";
import { motion, useAnimation } from "framer-motion";
import { FaArrowUp  } from "react-icons/fa6";
import { MdAttachEmail } from "react-icons/md";

const RevealLink = () => {
  const controls = useAnimation();
  return (
    <>
      <div className="flex flex-col mt-6">
        <span className="ml-8 md:ml-14 text-xs">(My Socials)</span>
        <section className="flex flex-col justify-center items-start">
          <FlipLink href="https://github.com/Animish2002">Github </FlipLink>
          <FlipLink href="https://x.com/animish06/">Twitter </FlipLink>
          <FlipLink href="https://www.linkedin.com/in/animish-chopade/">
            Linkedin
          </FlipLink>
          <FlipLink href="https://www.instagram.com/animish_06/">
            Instagram
          </FlipLink>
        </section>
        <div className="mt-12 md:mt-14 text-center relative">
          <p className="mb-1 text-lg md:text-xl">
            Ready to bring your ideas to life?
          </p>
          <p className="text-xs md:text-base text-[#fdf0d5]">
            © 2024 Animish. All rights reserved.
          </p>
          <motion.a
            href="mailto:animishchopade123@gmail.com"
            className="inline-flex items-center px-6 md:px-8 py-2 bg-none rounded-full font-semibold border border-white transition-all duration-300 mt-4 md:absolute md:bottom-4 md:right-8"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Drop a Mail
            <motion.span animate={controls}>
              <MdAttachEmail className="ml-2 text-2xl" />
            </motion.span>
          </motion.a>
        </div>
      </div>
    </>
  );
};

const DURATION = 0.25;
const STAGGER = 0.025;

const FlipLink = ({ children, href }) => {
  return (
    <motion.a
      initial="initial"
      whileHover="hovered"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="relative block overflow-hidden whitespace-nowrap text-4xl md:text-6xl uppercase text-left hover:border-y pl-6 md:pl-12 border-white w-full mt-2"
      style={{
        lineHeight: 0.94,
      }}
    >
      <div className="flex items-center">
        {children.split("").map((l, i) => (
          <motion.span
            key={i}
            variants={{
              initial: { y: 0 },
              hovered: { y: "-100%" },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * i,
            }}
            className="inline-block font-bold"
          >
            {l}
          </motion.span>
        ))}
        <motion.span
          variants={{
            initial: { opacity: 0 },
            hovered: { opacity: 1, y: 0 },
          }}
          transition={{
            duration: DURATION,
            ease: "easeInOut",
            delay: STAGGER * children.length,
          }}
          className="ml-2 "
        ></motion.span>
      </div>

      <div className="absolute inset-0 px-6 md:px-12 font-normal flex justify-between">
        <div>
          {children.split("").map((l, i) => (
            <motion.span
              key={i}
              variants={{
                initial: { y: "100%" },
                hovered: { y: 0 },
              }}
              transition={{
                duration: DURATION,
                ease: "easeInOut",
                delay: STAGGER * i,
              }}
              className="inline-block"
            >
              {l}
            </motion.span>
          ))}
        </div>
        <motion.span
          variants={{
            initial: { opacity: 0 },
            hovered: { opacity: 1, y: 0 },
          }}
          transition={{
            duration: DURATION,
            ease: "easeInOut",
            delay: STAGGER * children.length,
          }}
          className="ml-2"
        >
          <FaArrowUp className="rotate-45 font-extralight" />
        </motion.span>
      </div>
    </motion.a>
  );
};

export default RevealLink;
